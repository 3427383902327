import { tsConstructorType } from "@babel/types";
import React, { useEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import LoginPage from "./views/pages/login/Login";
import axios from "axios";
import "./scss/style.scss";
import {apiUrl} from './api'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Logout = React.lazy(() => import("./views/pages/logout/Logout"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

export const LoginContext = React.createContext({
  login: false,
  setLogin: (value) => {},
});

function App() {
  const [login, setLogin] = useState(false);

  async function checkToken() {
    if (localStorage.getItem("token") == null) {
      return false;
    } else {
      try {
        let response = await axios.get(apiUrl+"/verify", {
          headers: {
            "x-access-token": localStorage.getItem("token"),
          },
        });
        if (response.data.status === "success") {
          return true;
        }
        return false;
      } catch (err) {
        return false;
      }
    }
  }

  useEffect(() => {
    checkToken().then((value) => {
      setLogin(value);
    });
  }, []);

  if (login === true) {
    return (
      <LoginContext.Provider value={{ login: login, setLogin: setLogin }}>
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Route
                exact
                path="/logout"
                name="Logout"
                render={(props) => <Logout logout={() => setLogin(false)} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </LoginContext.Provider>
    );
  } else {
    const changeLoginState = (username, password) => {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);

      axios.post(apiUrl+"/login", formData).then((res) => {
        if (res.data.status === "success") {
          localStorage.setItem("token", res.data.accessToken);
          setLogin(true);
        }
      });
    };

    return (
      <LoginContext.Provider value={{ login: login, setLogin: setLogin }}>
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/"
                name="Login Page"
                render={(props) => (
                  <LoginPage {...props} login={changeLoginState}></LoginPage>
                )}
              />
              <Route exact path="/*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </React.Suspense>
        </HashRouter>
      </LoginContext.Provider>
    );
  }
}

export default App;
